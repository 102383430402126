// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-register-success-js": () => import("./../../../src/pages/register-success.js" /* webpackChunkName: "component---src-pages-register-success-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-categories-category-loadmore-js": () => import("./../../../src/templates/categories/category-loadmore.js" /* webpackChunkName: "component---src-templates-categories-category-loadmore-js" */),
  "component---src-templates-categories-category-regular-js": () => import("./../../../src/templates/categories/category-regular.js" /* webpackChunkName: "component---src-templates-categories-category-regular-js" */),
  "component---src-templates-games-game-js": () => import("./../../../src/templates/games/game.js" /* webpackChunkName: "component---src-templates-games-game-js" */),
  "component---src-templates-page-about-js": () => import("./../../../src/templates/page/about.js" /* webpackChunkName: "component---src-templates-page-about-js" */),
  "component---src-templates-page-activation-user-js": () => import("./../../../src/templates/page/activation-user.js" /* webpackChunkName: "component---src-templates-page-activation-user-js" */),
  "component---src-templates-page-banking-js": () => import("./../../../src/templates/page/banking.js" /* webpackChunkName: "component---src-templates-page-banking-js" */),
  "component---src-templates-page-casino-js": () => import("./../../../src/templates/page/casino.js" /* webpackChunkName: "component---src-templates-page-casino-js" */),
  "component---src-templates-page-deposit-js": () => import("./../../../src/templates/page/deposit.js" /* webpackChunkName: "component---src-templates-page-deposit-js" */),
  "component---src-templates-page-download-js": () => import("./../../../src/templates/page/download.js" /* webpackChunkName: "component---src-templates-page-download-js" */),
  "component---src-templates-page-iframe-login-js": () => import("./../../../src/templates/page/iframe-login.js" /* webpackChunkName: "component---src-templates-page-iframe-login-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-provider-js": () => import("./../../../src/templates/page/provider.js" /* webpackChunkName: "component---src-templates-page-provider-js" */),
  "component---src-templates-page-provider-sub-js": () => import("./../../../src/templates/page/provider-sub.js" /* webpackChunkName: "component---src-templates-page-provider-sub-js" */),
  "component---src-templates-page-register-js": () => import("./../../../src/templates/page/register.js" /* webpackChunkName: "component---src-templates-page-register-js" */),
  "component---src-templates-page-reset-password-js": () => import("./../../../src/templates/page/reset-password.js" /* webpackChunkName: "component---src-templates-page-reset-password-js" */),
  "component---src-templates-page-with-provider-carousel-js": () => import("./../../../src/templates/page/with-provider-carousel.js" /* webpackChunkName: "component---src-templates-page-with-provider-carousel-js" */),
  "component---src-templates-page-withdraw-js": () => import("./../../../src/templates/page/withdraw.js" /* webpackChunkName: "component---src-templates-page-withdraw-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-types-demo-list-js": () => import("./../../../src/templates/types/demo/list.js" /* webpackChunkName: "component---src-templates-types-demo-list-js" */),
  "component---src-templates-types-demo-post-js": () => import("./../../../src/templates/types/demo/post.js" /* webpackChunkName: "component---src-templates-types-demo-post-js" */)
}

