module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.gclub-online.org"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GClub Casino, lnwasia คาสิโน บาคาร่า มือถือ 100%","short_name":"GCLUB","start_url":"/","background_color":"#1A1D22","theme_color":"#ffe84d","display":"standalone","icon":"src/gclub-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"776b33142b95fa9762bebc469b220383"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M6NKW52H","includeInDevelopment":false,"timeout":2000},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
